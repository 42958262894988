import React, { useEffect } from "react";
import routes from "../config/routes.json";
import { isWindowAvailable } from "../helpers/helpers";

function IndexPage(props) {
  useEffect(() => {
    if (isWindowAvailable) window.location.href = routes.de.home;
  }, []);
  return <></>;
}

export default IndexPage;
